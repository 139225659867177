/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://x7p7q2dezzh3bbl6yqqtenul4i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vpR1CQsHI",
    "aws_user_pools_web_client_id": "1of513fg90q9j0rv8dcpjs17nm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AuditTrail-AuthenticatedOperations-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "AuditTrail-EventNames-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsCategories-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsEventLog-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsExercises-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsPatients-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsTreatments-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAppEvents-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "MobileDeviceInfo-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "SharedData-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "SimMapping-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "TreatmentDefinitions-vnv",
            "region": "us-east-1"
        },
        {
            "tableName": "UniqueValues-vnv",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "bq-contentcd350-vnv",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
